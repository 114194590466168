import React from "react";
import "../scss/morse.scss";

const StandardLayout = (props: any) => {
    return <>
            <div className="c-header">

                <div className="l-wrap u-flex u-align-items-center">

                <input type="checkbox" className="c-nav-check" id="nav-check" />
                <a href="/" className="c-header__logo">
                    <img src="/images/burke-logo.svg" alt="Burke Shipping Logo"/>
                </a>

                    <ul className="c-nav +primary +inline@m +sub-nav-hover +padded-items">
                        <li className="c-nav__item">
                            <a href="/">Home</a>
                        </li>
                        <li className="c-nav__item">
                            <a href="/services">Services</a>
                            <ul className="c-nav +subnav u-pos-bottom-left u-nowrap">
                                <li>
                                    <a href="/ships-agency">Ships Agency</a>
                                </li>
                                <li>
                                    <a href="/stevedoring">Stevedoring</a>
                                </li>
                                <li>
                                    <a href="/warehousing">Warehousing</a>
                                </li>
                            </ul>
                        </li>
                        <li className="c-nav__item">
                            {/* You don't have to use Link components, you can equally use <a> and hrefs! */}
                            <a href="/about">About</a>
                        </li>
                        <li className="c-nav__item">
                            <a href="/contact">Contact</a>
                        </li>
                    </ul>


                <label htmlFor="nav-check" className="c-nav-button"><span className="c-nav-button__inner"></span></label>

                </div>
            </div>

            <main role="main" className="c-main">


                {props.children}


            </main>

            <div className="c-footer u-pad-large">
                <div className="l-wrap">

                    <div className="u-flex@m u-justify-content-between">
                        <p className="u-font-small">Copyright © 2020. All Rights Reserved</p>
                        <ul className="u-font-small u-inline-flex">
                            <li className="u-marg-right"><a href="/terms-and-conditions">Terms & Conditions</a></li>
                            <li className="u-marg-right"><a href="https://fanevalley.com/modern-slavery/" target="_blank">Modern Slavery Policy</a></li>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
    </>
}

export default StandardLayout;